import React, { useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { normalize } from 'normalizr'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import truncate from 'lodash/truncate'

import {
  materialItemAdd,
  materialItemPositionChange,
  materialSearchChoose
} from '../../actions'
import { getConfiguration, getItem } from '../../selectors'
import { PaperworkContext, ItemContext } from '../../contexts'
import Item from './item'
import MaterialSearchButton from '../material-search/button'
import HelpPrompt from '../help-prompt'
import { materialItem as materialItemSchema } from '../../schemas'
import setNewUuids from '../../helpers/set-new-uuids'
import ItemTotal from '../totals/items'

const SortableMaterialItem = SortableElement(Item)
const MaterialItemList = SortableContainer(({ materialItems }) => (
  <div>
    {
      materialItems.map((materialItemUUID, index) => (
        <SortableMaterialItem
          materialItemUUID={materialItemUUID}
          key={materialItemUUID}
          index={index}
          isFirstItem={index === 0}
        />
      ))
    }
  </div>
))

const MaterialItems = () => {
  const dispatch = useDispatch()
  const { paperworkUUID } = useContext(PaperworkContext)
  const { itemUUID } = useContext(ItemContext)
  const item = useSelector(state => getItem(state, itemUUID))
  const [rowAddedClass, setRowAddedClass] = useState('')
  const { currencyUnit } = useSelector(getConfiguration)
  const isMultipliedByItemQuantity = item.quantity > 1

  return (
    <div data-test='materials-panel'>
      <div className='c-panel__header c-panel__header--fixed c-panel__header--pad-right'>
        <h1 className='t--lrg u-mrgn-btm' data-test='material-panel-title'>
          {item.title.trim().length > 0
            ? (
              <strong>
                {`Material costs for ${truncate(item.title, { length: 75 })}`}
              </strong>
              )
            : <strong>Material costs</strong>}
        </h1>
        <div className='u-visible-sml-scrn-only'>
          {isMultipliedByItemQuantity
            ? (
              <div>
                <h2 className='t--reg'>
                  <span className='bg--light-yellow u-pad--x3'>
                    Materials multiplied by item qty
                    <strong>
                      &nbsp;&times;
                      {item.quantity}
                    </strong>
                  </span>
                </h2>
                <h2 className='t--reg'>
                  <span className='bg--light-yellow u-pad--x3'>
                    <span data-test='material-items-line-total'>
                      <strong><ItemTotal name='materialItemsLineTotal' /></strong>
                    </span>
                    &nbsp; &middot; &nbsp;
                    <span className='c--dark-metal' data-test='material-items-line-subtotal'>
                      <ItemTotal name='materialItemsLineSubTotal' />
                      {' ex. markup'}
                    </span>
                  </span>
                </h2>
              </div>
              )
            : (
              <div>
                <h2 className='t--reg'>
                  <span className='u-pad--x3'>
                    <span data-test='material-items-line-total'>
                      <strong><ItemTotal name='materialItemsTotal' /></strong>
                    </span>
                    &nbsp; &middot; &nbsp;
                    <span className='c--dark-metal' data-test='material-items-line-subtotal'>
                      <ItemTotal name='materialItemsSubTotal' />
                      {' ex. markup'}
                    </span>
                  </span>
                </h2>
              </div>
              )}
        </div>
      </div>

      <HelpPrompt name='using_materials' />
      <HelpPrompt name='importing_materials' />

      <div className={`m-table ${rowAddedClass}`} data-test='material-items'>
        <ul className='m-table__head'>
          <li className='m-table__cell m-table__cell--title'>Material</li>
          <li className='m-table__cell m-table__cell--qty'>{isMultipliedByItemQuantity ? 'Qty/item' : 'Qty'}</li>
          <li className='m-table__cell m-table__cell--units'>Units</li>
          <li className='m-table__cell m-table__cell--price'>
            {currencyUnit}
            &nbsp;per unit
          </li>
          <li className='m-table__cell m-table__cell--markup'>
            &#37; markup
          </li>
          <li className='m-table__cell m-table__cell--total'>Total</li>
          {isMultipliedByItemQuantity && (
            <li className='m-table__cell m-table__cell--total'>
              Total &times;&nbsp;
              {item.quantity}
            </li>
          )}
          <li className='m-table__cell m-table__cell--actions'>&nbsp;</li>
        </ul>
        <MaterialItemList
          materialItems={item.materialItems}
          useDragHandle
          lockAxis='y'
          helperClass='m-table__row--dragging'
          onSortEnd={({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex) { return }
            dispatch(materialItemPositionChange(itemUUID, oldIndex, newIndex))
          }}
        />
        <div className='m-table__totals'>
          <div className='m-table__totals-filler' />
          <div className='m-table__totals-material'>
            &nbsp;
            <br />
            <span className='t--sml c--dark-metal'>
              excl. markup:
            </span>
          </div>
          <div className='m-table__totals-material'>
            <span data-test='material-items-total'>
              <strong><ItemTotal name='materialItemsTotal' /></strong>
            </span>
            <br />
            <span className='t--sml c--dark-metal' data-test='material-items-subtotal'>
              <ItemTotal name='materialItemsSubTotal' />
            </span>
          </div>
          {isMultipliedByItemQuantity && (
            <div className='m-table__totals-material bg--light-yellow'>
              <strong><ItemTotal name='materialItemsLineTotal' /></strong>
              <br />
              <span className='t--sml c--dark-metal' data-test='material-items-line-subtotal'>
                <ItemTotal name='materialItemsLineSubTotal' />
              </span>
            </div>
          )}
        </div>
        <div className='pwrk-frm__add-section pwrk-frm__add-section--materials'>
          <button
            data-test='add-material-button'
            className='btn__add'
            type='button'
            onClick={() => {
              setRowAddedClass('m-table--row-added')
              dispatch(materialItemAdd(paperworkUUID, itemUUID))
            }}
          >
            Add new row
          </button>

          <MaterialSearchButton
            onResultClick={material => {
              dispatch(materialSearchChoose(itemUUID, normalize(setNewUuids(material), materialItemSchema)))
            }}
          >
            Add from material list
          </MaterialSearchButton>
        </div>
      </div>

    </div>
  )
}

export default MaterialItems
