/* eslint no-restricted-globals: 0 */

import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'
import Cookies from 'js-cookie'
import axios from '@ytb/axios'
import Turbo from '@/application/turbo'

import renderPaperworkGroupHeadings from '@/application/paperwork-group-headings'

export default class SearchController extends Controller {
  static targets = ['form', 'text', 'entries', 'pagination', 'export', 'exportForm', 'filters', 'filtersList', 'filterNumber']

  connect () {
    this.search = debounce(this.search, 400).bind(this)

    if (this.hasFiltersTarget) {
      this.setFriendlyFilters()
    }
  }

  search () {
    const url = new URL(window.location)

    const formData = new FormData(this.formTarget)

    Array.from(formData.entries()).forEach(entry => {
      url.searchParams.set(entry[0], entry[1])
    })

    Turbo.navigator.history.push(url)

    if (this.hasFiltersTarget) {
      this.setFriendlyFilters()
      Cookies.set(
        `${url.pathname.substring(1)}_index_filters_v1`,
        url.searchParams.toString(),
        { expires: 365 }
      )
    }

    this.fetchResults(url.href)
  }

  setFriendlyFilters () {
    if (!this.hasFiltersTarget) {
      return false
    }

    const friendlyTargets = this.filtersListTarget.dataset.searchFriendly.split(',')
    const friendlyStr = []

    friendlyTargets.forEach(target => {
      if (
        this.formTarget.querySelector(`[name=${target}]`) &&
        this.formTarget.querySelector(`[name=${target}]:checked`)
      ) {
        const { dataset: { friendlyName } } = this.formTarget.querySelector(`[name=${target}]:checked`)
        const { dataset: { friendlyName: firstOptionName } } = this.formTarget.querySelector(`[name=${target}]`)

        if (friendlyName !== firstOptionName) {
          friendlyStr.push(friendlyName)
        }
      }
    })

    this.filterNumberTarget.innerHTML = (friendlyStr.length > 0) ? `(${friendlyStr.length})` : ''
    this.filtersListTarget.innerHTML = friendlyStr.join(' &middot; ')

    if (friendlyStr.length > 0) {
      this.filtersTarget.classList.add(this.filtersTarget.dataset.activeClass)
    } else {
      this.filtersTarget.classList.remove(this.filtersTarget.dataset.activeClass)
    }

    return true
  }

  fetchResults (url) {
    const isGrouped = this.data.get('grouped')

    axios.get(url,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.entriesTarget.innerHTML = response.data.entries
        this.paginationTarget.innerHTML = response.data.pagination

        if (this.hasExportTarget) {
          this.exportTarget.href = response.data.exportUrl
        }
        if (this.hasExportFormTarget) {
          this.exportFormTarget.action = response.data.backgroundExportUrl
        }

        if (isGrouped) {
          renderPaperworkGroupHeadings()
        }
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          window.location.reload()
        } else {
          throw error
        }
      })
  }

  stopSubmit (event) {
    // This is separate to filter due to filter being triggered
    // by the `input` event, which doesn't send keycode.
    const ENTER = 13
    if (event.keyCode === ENTER) {
      event.preventDefault()
    }
  }
}
