/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import enAuLocale from '@fullcalendar/core/locales/en-au'
import moment from 'moment'
import Turbo from '@/application/turbo'

import updateEvent from './helpers/events/update'
import showEvent from './helpers/events/show'

import '@fullcalendar/core/main.css'
import '@fullcalendar/timeline/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import '@fullcalendar/resource-timeline/main.css'

window.App = window.App || {}

class CalendarApp extends Component {
  calendarRef = React.createRef()

  handleDateClick = arg => {
    const { newEventPath } = this.props

    if (newEventPath.length > 0) {
      const { dateStr, resource: { id: assigneeId = 'unassigned' } = {} } = arg
      const assignee = (assigneeId === 'unassigned') ? '' : `&assignee=${assigneeId}`

      window.open(`${newEventPath}${dateStr}${assignee}`, '_self')
    }
  }

  handleEventClick = info => {
    info.jsEvent.preventDefault()
    showEvent(info.event.url, info.el)
  }

  handleEventUpdate = eventInfo => {
    switch (eventInfo.event.extendedProps.type) {
      case 'GeneralEvent':
      case 'WorkDate':
      case 'Appointment':
        updateEvent(
          eventInfo.event,
          eventInfo.newResource,
          eventInfo.oldResource,
          eventInfo.revert
        )
        break
      default:
        Sentry.captureMessage(`Calendar attempted to update an event of type "${eventInfo.event.extendedProps.type}" which is unsupported. Called in calendar component.`)
    }
  }

  handleViewChange = viewInfo => {
    const { cookieName } = this.props

    Cookies.set(cookieName, viewInfo.view.type)
    viewInfo.view.context.calendar.scrollToTime(this.scrollToTime())
  }

  calendarDefaultView = () => {
    const { cookieName, defaultView } = this.props
    return Cookies.get(cookieName) || defaultView
  }

  viewButtons = () => {
    const { calendarViews, displayResourceView, resourceViews } = this.props
    if (displayResourceView) {
      return [...calendarViews, ...resourceViews].join()
    }
    return calendarViews.join()
  }

  customButtons = () => {
    const { customButtons } = this.props

    return {
      prev: {
        text: 'prev',
        click: () => {
          const calendarApi = this.calendarRef.current.getApi()

          calendarApi.prev()
          this.storeCurrentDate(calendarApi.getDate())
        }
      },
      next: {
        text: 'next',
        click: () => {
          const calendarApi = this.calendarRef.current.getApi()

          calendarApi.next()
          this.storeCurrentDate(calendarApi.getDate())
        }
      },
      today: {
        text: 'today',
        click: () => {
          const calendarApi = this.calendarRef.current.getApi()

          calendarApi.today()
          this.storeCurrentDate(calendarApi.getDate())
        }
      },
      ...customButtons
    }
  }

  storeCurrentDate = date => {
    Cookies.set('calendar_date', moment(date).format(), { expires: moment().add(2, 'hours').toDate() })
  }

  defaultDate = () => {
    const today = moment().format()

    return Cookies.get('calendar_date') || today
  }

  scrollToTime = () => {
    const { cookieName } = this.props

    if (Cookies.get(cookieName) === 'resourceTimelineMonth') {
      return moment()
    }

    return moment().subtract(2, 'hour').diff(moment().startOf('isoWeek'))
  }

  render () {
    const {
      dayGridFormats, eventsSource,
      resourceTimelineWeekFormats, resourceTimelineMonthFormats,
      timeGridFormats, resources,
      editable
    } = this.props

    return (
      <FullCalendar
        defaultDate={this.defaultDate()}
        ref={this.calendarRef}
        schedulerLicenseKey='0122480487-fcs-1565622622'
        defaultView={this.calendarDefaultView()}
        customButtons={this.customButtons()}
        header={{
          left: 'prev,next title today',
          center: '',
          right: `${this.viewButtons()} schedule,calendar`
        }}
        nowIndicator
        views={{
          dayGrid: dayGridFormats,
          timeGrid: timeGridFormats,
          resourceTimelineWeek: resourceTimelineWeekFormats,
          resourceTimelineMonth: resourceTimelineMonthFormats
        }}
        locale={enAuLocale}
        dateClick={this.handleDateClick}
        eventClick={this.handleEventClick}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin]}
        eventSources={[eventsSource]}
        resources={resources}
        resourceLabelText='Team members'
        resourceGroupField='group'
        resourceOrder='-group, title'
        eventDrop={this.handleEventUpdate}
        eventResize={this.handleEventUpdate}
        viewSkeletonRender={this.handleViewChange}
        scrollTime={this.scrollToTime()}
        editable={editable}
      />
    )
  }
}

CalendarApp.propTypes = {
  calendarViews: PropTypes.array,
  resourceViews: PropTypes.array,
  resources: PropTypes.array,
  displayResourceView: PropTypes.bool.isRequired,
  defaultView: PropTypes.string,
  cookieName: PropTypes.string,
  eventsSource: PropTypes.object,
  newEventPath: PropTypes.string,
  customButtons: PropTypes.object,
  dayGridFormats: PropTypes.object,
  timeGridFormats: PropTypes.object,
  editable: PropTypes.bool,
  resourceTimelineWeekFormats: PropTypes.object,
  resourceTimelineMonthFormats: PropTypes.object
}

CalendarApp.defaultProps = {
  editable: true,
  calendarViews: ['timeGridWeek', 'dayGridMonth'],
  resourceViews: ['resourceTimelineWeek', 'resourceTimelineMonth'],
  defaultView: 'dayGridMonth',
  cookieName: 'calendarViewV4',
  eventsSource: {
    url: '/calendar/events.json',
    startParam: 'start_date',
    endParam: 'end_date'
  },
  newEventPath: '/events/select_type?start_date=',
  customButtons: {
    schedule: {
      text: 'Schedule',
      click: () => { Turbo.visit('/schedule') }
    },
    calendar: {
      text: 'Calendar',
      click: () => { Turbo.visit('/calendar') }
    }
  },
  dayGridFormats: {
    buttonText: 'Month',
    eventTimeFormat: { hour: 'numeric', hour12: window.App.useTwelveHourFormat, meridiem: 'short', minute: '2-digit' }
  },
  timeGridFormats: {
    buttonText: 'Week',
    titleFormat: { day: '2-digit', month: 'long', year: 'numeric' },
    eventTimeFormat: { hour: 'numeric', hour12: window.App.useTwelveHourFormat, meridiem: 'short', minute: '2-digit', omitZeroMinute: window.App.useTwelveHourFormat },
    columnHeaderFormat: { weekday: 'short', day: 'numeric' },
    slotLabelFormat: { hour: 'numeric', minute: '2-digit', hour12: window.App.useTwelveHourFormat, meridiem: 'short', omitZeroMinute: window.App.useTwelveHourFormat }
  },
  resourceTimelineWeekFormats: {
    buttonText: 'Team hour',
    titleFormat: { day: '2-digit', month: 'long', year: 'numeric' },
    slotLabelFormat: [
      { weekday: 'short', day: 'numeric', hour12: window.App.useTwelveHourFormat },
      { hour: 'numeric', hour12: window.App.useTwelveHourFormat, minute: '2-digit', meridiem: 'short', omitZeroMinute: window.App.useTwelveHourFormat }
    ],
    resourceAreaWidth: '20%',
    slotWidth: '120'
  },
  resourceTimelineMonthFormats: {
    buttonText: 'Team day',
    titleFormat: { day: '2-digit', month: 'long', year: 'numeric' },
    eventTimeFormat: { hour: 'numeric', hour12: window.App.useTwelveHourFormat, meridiem: 'short', minute: '2-digit', omitZeroMinute: window.App.useTwelveHourFormat },
    slotLabelFormat: [
      { weekday: 'short', day: 'numeric', month: 'short', omitCommas: true }
    ],
    resourceAreaWidth: '20%',
    slotWidth: '120',
    duration: { weeks: 4 }
  },
  resources: []
}

export default CalendarApp
