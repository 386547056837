import axios from '@ytb/axios'

import { clickOutsideHandler, closePopups, createPopup } from '@/application/popper'

document.addEventListener('click', clickOutsideHandler)

export default function showEvent (url, eventElem) {
  const calElm = document.querySelector('[data-calendar]')

  axios.get(url).then(response => {
    closePopups()
    createPopup(response.data, eventElem, calElm)
  })
}
