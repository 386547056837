import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import PriceListSearch from './index'
import Modal from '../../../modal/index'

const Button = ({ children, onResultClick }) => {
  const [showSearch, setShowSearch] = useState(false)
  return (
    <>
      <button
        onClick={() => { setShowSearch(true) }}
        data-test='add-item-from-price-list'
        className='btn__add btn__add--sml'
        type='button'
      >
        {children}
      </button>
      {
        showSearch && (
          <Modal closeText='Close' onClose={() => setShowSearch(false)}>
            <PriceListSearch
              onResultClick={item => {
                onResultClick(item)
                setShowSearch(false)
              }}
            />
          </Modal>
        )
      }
    </>
  )
}

Button.propTypes = {
  onResultClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default Button
