import React from 'react'
import PropTypes from 'prop-types'

const confirmDialog = () => window.confirm('This ready-written template will replace what you’ve already typed. Want to go ahead?')

const handleClick = (e, onClick, showDialog) => {
  if (showDialog && !confirmDialog()) { return }

  onClick(e)
}

const truncate = (str, len = 140) => str.substring(0, len).trim() + (str.length > len ? String.fromCharCode(8230) : '')

const EmailTemplate = ({
  id,
  title,
  subject,
  summary,
  onClick,
  confirmClick
}) => (
  <li id={`email_template_${id}`}>
    <div onClick={e => { handleClick(e, onClick, confirmClick) }} className='choose-template__item flex-grid'>
      <div className='flex-grid__cell'>
        <p className='choose-template__item-title'>
          {title}
        </p>
        <p className='choose-template__item-subject'>
          {subject}
        </p>
        <p className='choose-template__item-excerpt'>
          {truncate(summary)}
        </p>
      </div>
      <div className='flex-grid__cell flex-grid__cell--1of4 flex-grid__cell--v-center u-align-center'>
        <p className='btn__add u-mrgn-top u-mrgn-btm'>
          Use template
        </p>
      </div>
    </div>
  </li>
)

EmailTemplate.defaultProps = {
  id: 0,
  title: '',
  subject: '',
  summary: '',
  onClick: () => {},
  confirmClick: false
}

EmailTemplate.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  subject: PropTypes.string,
  summary: PropTypes.string,
  onClick: PropTypes.func,
  confirmClick: PropTypes.bool
}

export default EmailTemplate
