import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { showTemplatePanel } from '../actions'

const TemplateButton = ({ templatesUrl }) => {
  const dispatch = useDispatch()

  return (
    <button
      type='button'
      onClick={event => {
        event.preventDefault()
        dispatch(showTemplatePanel(templatesUrl))
      }}
      id='template-button'
      className='link t--sml u-mrgn-r'
    >
      Use a template
    </button>
  )
}

TemplateButton.propTypes = {
  templatesUrl: PropTypes.string.isRequired
}

export default TemplateButton
