/* eslint react/no-danger: 0 */

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import InnerHTML from 'dangerously-set-html-content'
import axios from '@ytb/axios'

import CloseSvg from '@/components/svg/close'

const HelpPrompt = ({ name, orientation, visible, content, closeUrl }) => {
  const [isOpen, setIsOpen] = useState(visible)

  const handleClose = useCallback(() => {
    axios.post(closeUrl)
    setIsOpen(false)
  }, [closeUrl])

  if (!isOpen) { return null }

  return (
    <div className={`help-prompt ${orientation} ${name} u-pad-r--x6`}>
      <button
        type='button'
        className='help-prompt__close btn'
        onClick={handleClose}
      >
        <CloseSvg className='icon--dark-metal' />
      </button>
      <p>
        <InnerHTML html={content} />
      </p>
    </div>
  )
}

HelpPrompt.propTypes = {
  name: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  content: PropTypes.string.isRequired,
  closeUrl: PropTypes.string.isRequired
}

HelpPrompt.defaultProps = {
  visible: true
}

export default HelpPrompt
