import React from 'react'
import PropTypes from 'prop-types'

import MenuMoreSolidSvg from '@/components/svg/menu-more--solid'

const Dropdown = ({
  type,
  label,
  menuTitle,
  containerClassName,
  buttonClassName,
  testKey,
  buttonTestKey,
  children
}) => (
  <div
    className={`btn__dropdown ${containerClassName}`}
    data-test={testKey}
    data-controller='dropdown'
    data-dropdown-class='menu--open'
  >
    <button
      type='button'
      className={`btn btn--white ${buttonClassName}`}
      style={{ marginRight: '-12px' }}
      data-action='dropdown#toggle'
      data-test={buttonTestKey}
    >
      {(type === 'ellipsis') ? <MenuMoreSolidSvg className='icon icon--sml' /> : label}
    </button>
    <div className='menu menu--anchor-left' data-dropdown-target='target'>
      <div className='menu__contents'>
        <div className='menu__header'>
          <h4 className='menu__title'>{menuTitle}</h4>
          <span className='menu__close' data-action='click->dropdown#toggle'>Done</span>
        </div>
        <div className='menu__col'>
          <ul className='menu__item' data-dropdown-target='target' data-test='event-menu'>
            {children}
          </ul>
        </div>
      </div>
    </div>
  </div>
)

Dropdown.defaultProps = {
  type: '',
  label: '',
  menuTitle: 'Actions',
  containerClassName: '',
  buttonClassName: '',
  testKey: '',
  buttonTestKey: ''
}

Dropdown.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  menuTitle: PropTypes.string,
  containerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  testKey: PropTypes.string,
  buttonTestKey: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Dropdown
