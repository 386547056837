import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import stripHtml from 'string-strip-html'
import truncate from 'lodash/truncate'
import Money from '../money'

const Result = ({ item, onResultClick }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (item.selected) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [item])

  return (
    <li key={item.id} ref={ref} className={item.selected ? 'c-card price-list-search__card price-list-search__card--selected' : 'c-card price-list-search__card'}>
      <a
        onClick={() => { onResultClick(item) }}
        role='button'
        tabIndex={0}
        data-test='price-list-search-results-link'
      >
        <div className='flex-grid'>
          <div className='flex-grid__cell flex-grid__cell--v-center'>
            <h4 className='t--reg'>
              <strong>{item.title}</strong>
            </h4>
            <p className='t--sml c--dark-metal'>
              {
                truncate(
                  stripHtml(item.description).result,
                  { length: 300, separator: '...' }
                )
              }
            </p>
          </div>
          <div className='flex-grid__cell flex-grid__cell--1of10 flex-grid__cell--v-top'>
            <p className='t--sml u-pad-top u-align-right'>
              <strong><Money amount={item.total} /></strong>
            </p>
          </div>
          <div className='flex-grid__cell flex-grid__cell--1of4 flex-grid__cell--v-top price-list-search__card-add'>
            <span className='btn__add'>
              Add this item
            </span>
          </div>
        </div>
      </a>
    </li>
  )
}

Result.propTypes = {
  item: PropTypes.object.isRequired,
  onResultClick: PropTypes.func.isRequired
}

export default Result
