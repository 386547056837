import React, { useEffect, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'

import Panel from './panel'

const ESC_KEY = 27

const Modal = props => {
  const appRoot = document.getElementById('modal-container')
  const appBody = document.getElementsByTagName('body')[0]
  const [container] = useState(document.createElement('div'))

  const { onClose } = props
  const onEscape = useCallback(event => {
    if (event.keyCode !== ESC_KEY) { return }

    onClose(event)
  }, [onClose])

  useEffect(() => {
    // on component mount
    document.dispatchEvent(new Event('modal-open'))
    appBody.style.top = `-${window.scrollY}px`
    appBody.style.width = '100%'
    appBody.style.position = 'fixed'

    appRoot.appendChild(container)
    window.addEventListener('keyup', onEscape, { passive: true })
    return () => {
      // on component unmount
      const scrollY = appBody.style.top
      appRoot.removeChild(container)
      appBody.style.position = ''
      appBody.style.width = ''
      appBody.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      document.dispatchEvent(new Event('modal-close'))
      window.removeEventListener('keyup', onEscape)
    }
  }, [])

  return ReactDOM.createPortal(
    (
      <Panel {...props} />
    ),
    container
  )
}

export default Modal
