import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import axios from '@ytb/axios'

import './css'
import FileInput from './components/file-input'
import ThumbnailList from './components/thumbnail-list'
import uploader from './helpers/uploader'
import ErrorList from './components/error-list'
import useBeforeUnload from './helpers/use-before-unload-hook'

const Contact = ({
  initialFiles,
  attachableType,
  attachableId,
  endpoint,
  companyHasFieldStaff,
  restrictions
}) => {
  const [files, setFiles] = useState(initialFiles)
  const [errors, setErrors] = useState([])
  const [uploading, setUploading] = useState(false)

  const onUploadEnd = file => {
    setErrors([])
    setFiles(currentFiles => [file, ...currentFiles])
    setUploading(false)
  }

  const handleError = error => {
    setErrors([...errors, error])
    setUploading(false)
  }

  const fileUploader = useMemo(() => (
    uploader({
      fileType: 'file',
      onUploadStart: () => setUploading(true),
      onUploadEnd,
      endpoint: '/attachments',
      params: {
        attachable_id: attachableId,
        attachable_type: attachableType
      },
      restrictions,
      handleError
    })
  ), [files, errors])

  useEffect(() => () => fileUploader.close)
  useBeforeUnload(uploading)

  const handleDelete = id => {
    axios.delete(`/attachments/${id}`)
      .then(() => {
        const filteredFiles = files.filter(file => file.id !== id)
        setFiles([...filteredFiles])
      })
  }

  return (
    <div className='file-uploader'>
      <FileInput
        uploader={fileUploader}
        buttonClass='btn__add btn__add--sml'
        label='Upload a file'
        uploading={uploading}
      />
      <ThumbnailList
        files={files}
        endpoint={endpoint}
        companyHasFieldStaff={companyHasFieldStaff}
        onDelete={handleDelete}
      >
        <p className='c--metal u-mrgn-top'>
          No uploaded files
        </p>
      </ThumbnailList>
      <ErrorList errors={errors} />
    </div>
  )
}

Contact.defaultProps = {
  companyHasFieldStaff: false,
  restrictions: {}
}

Contact.propTypes = {
  initialFiles: PropTypes.array.isRequired,
  attachableType: PropTypes.string.isRequired,
  attachableId: PropTypes.number.isRequired,
  endpoint: PropTypes.string.isRequired,
  companyHasFieldStaff: PropTypes.bool,
  restrictions: PropTypes.object
}

export default Contact
