/* global IntersectionObserver */

import { Controller } from 'stimulus'
import autosize from 'autosize'

class AutosizeController extends Controller {
  initialize () {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          autosize.update(entry.target)
        }
      })
    })
  }

  connect () {
    autosize(this.element)

    this.intersectionObserver.observe(this.element)
  }

  disconnect () {
    this.intersectionObserver.unobserve(this.element)
    autosize.destroy(this.element)
  }
}

export default AutosizeController
