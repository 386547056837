import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    dialogId: String
  }

  change () {
    const appController = document.body.appController

    if (this.element.value === 'new') {
      appController.dialogShow({ params: { dialogId: this.dialogIdValue } })
    }
  }
}
