import { Controller } from 'stimulus'

export default class AppController extends Controller {
  static targets = ['dialog']

  initialize () {
    document.dispatchEvent(new Event('app-initialize'))
  }

  connect () {
    this.element.appController = this
    document.dispatchEvent(new Event('app-connect'))
  }

  disconnect () {
    document.dispatchEvent(new Event('app-disconnect'))
  }

  dialogShow ({ params: { dialogId } }) {
    this.dialogTargets.filter(element => element.id === dialogId).forEach(element => element.dialog.show())
  }
}
