import { Controller } from 'stimulus'
import A11yDialog from 'a11y-dialog'

export default class DialogController extends Controller {
  static values = {
    visible: { type: Boolean, default: false }
  }

  connect () {
    this.element.dialog = this
    this.dialog = new A11yDialog(this.element)

    if (this.visibleValue) {
      this.show()
    }
  }

  show () {
    this.dialog.show()
    this.dispatch('show')
    setTimeout(() => {
      document.body.classList.toggle('overlay-open')
    }, 20)
  }

  hide () {
    this.dialog.hide()
    this.dispatch('hide')
    setTimeout(() => {
      document.body.classList.remove('overlay-open')
    }, 20)
  }

  submitEnd (event) {
    if (event.detail.success) {
      this.hide()
    }
  }

  disconnect () {
    this.dialog.destroy()
  }
}
