/* eslint no-restricted-globals: 0 */

import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'
import Cookies from 'js-cookie'
import Turbo from '@/application/turbo'

export default class TsearchController extends Controller {
  static targets = ['submit', 'reload', 'form', 'text', 'pagination', 'filters', 'filtersList', 'filterNumber', 'currentFiltersText', 'disabled']

  connect () {
    this.submit = debounce(this.submit, 400).bind(this)

    this.setFriendlyFilters()
    this.updateCookies()
  }

  currentFiltersTextTargetConnected () {
    this.setFriendlyFilters()
  }

  disabledTargetConnected () {
    this.formTarget.classList.add('hidden')
  }

  disabledTargetDisconnected () {
    this.formTarget.classList.remove('hidden')
  }

  submit (event) {
    this.setFriendlyFilters()
    this.updateCookies()
    const url = new URL(window.location)

    const formData = new FormData(this.formTarget)

    Array.from(formData.entries()).forEach(entry => {
      url.searchParams.set(entry[0], entry[1])
    })

    Turbo.navigator.history.push(url)
    this.submitTarget.click()
    event.preventDefault()
  }

  noop () {
  }

  setFriendlyFilters () {
    if (!this.hasFiltersTarget) {
      return
    }

    const friendlyTargets = this.filtersListTarget.dataset.searchFriendly.split(',')
    const friendlyStr = []

    friendlyTargets.forEach(target => {
      if (
        this.formTarget.querySelector(`[name=${target}]`) &&
        this.formTarget.querySelector(`[name=${target}]:checked`)
      ) {
        const { dataset: { friendlyName } } = this.formTarget.querySelector(`[name=${target}]:checked`)
        const { dataset: { friendlyName: firstOptionName } } = this.formTarget.querySelector(`[name=${target}]`)

        if (friendlyName !== firstOptionName) {
          friendlyStr.push(friendlyName)
        }
      }
    })

    this.filterNumberTarget.innerHTML = (friendlyStr.length > 0) ? `(${friendlyStr.length})` : ''

    this.filtersListTarget.innerHTML = friendlyStr.join(' &middot; ')

    this.currentFiltersTextTargets.forEach(target => {
      target.innerHTML = friendlyStr.join(' &middot; ')
    })

    if (friendlyStr.length > 0) {
      this.filtersTarget.classList.add(this.filtersTarget.dataset.activeClass)
    } else {
      this.filtersTarget.classList.remove(this.filtersTarget.dataset.activeClass)
    }
  }

  stopSubmit (event) {
    const ENTER_KEY = 13
    if (event.keyCode === ENTER_KEY) {
      event.preventDefault()
    }
  }

  // Private
  updateCookies () {
    const searchParams = new URLSearchParams(new FormData(this.formTarget))
    const url = new URL(window.location)
    Cookies.set(
      `${url.pathname.substring(1)}_index_filters_v1`,
      searchParams.toString(),
      { expires: 365 }
    )
  }
}
