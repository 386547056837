/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MessageBody extends Component {
  constructor (props) {
    super(props)

    this.state = {
      contentType: 'body',
      content: props.body
    }

    this.switchContent = this.switchContent.bind(this)
    this.isCorrectTemplate = this.isCorrectTemplate.bind(this)
    this.showViewFullBodyButton = this.showViewFullBodyButton.bind(this)
  }

  isCorrectTemplate () {
    const { template } = this.props
    return ['reply', 'client_question'].includes(template)
  }

  switchContent () {
    const { contentType } = this.state
    const { body, full_body: fullBody } = this.props
    let newState = this.state

    if (contentType === 'body') {
      newState = { contentType: 'fullBody', content: fullBody }
    } else {
      newState = { contentType: 'body', content: body }
    }
    this.setState(newState)
  }

  showViewFullBodyButton () {
    const { full_body: fullBody } = this.props
    return this.isCorrectTemplate() && fullBody.length !== 0
  }

  render () {
    const { content } = this.state

    return (
      <div className='conversation__msg-body'>
        <div dangerouslySetInnerHTML={{ __html: content }} className='trix-content' />
        {
          this.showViewFullBodyButton() &&
          (
            <div style={{ top: '-12px' }}>
              <button
                type='button'
                onClick={this.switchContent}
                className='btn btn--tiny btn--purple btn--no-border'
                data-controller='tooltip'
                data-tooltip-content-value='Toggle full message'
              >
                <div className='icon icon--sml icon--dark-metal u-mrgn-btm--x0'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                    <circle cx='3.25' cy='12' r='3.25' />
                    <circle cx='12' cy='12' r='3.25' />
                    <circle cx='20.75' cy='12' r='3.25' />
                  </svg>
                </div>
              </button>
            </div>
          )
        }
      </div>
    )
  }
}

MessageBody.propTypes = {
  body: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  full_body: PropTypes.string
}

MessageBody.defaultProps = {
  full_body: ''
}

export default MessageBody
