import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FiltersSvg from '@/components/svg/filters'
import { camelizeKeys } from 'humps'
import { materialCategories, materialBrands } from '../../services'

const MaterialFilters = ({ onCategorySelect, onBrandSelect, selectedCategory, selectedBrand }) => {
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])

  useEffect(() => {
    materialCategories().then(response => {
      setCategories(camelizeKeys(response.data))
    })
  }, [])

  useEffect(() => {
    materialBrands().then(response => {
      setBrands(camelizeKeys(response.data))
    })
  }, [])

  return (
    <div className='btn__dropdown' data-controller='dropdown' data-dropdown-class='menu--open'>
      <button type='button' className='srch-tlbr__btn' data-action='dropdown#toggle' data-test='filters-menu' data-tsearch-target='filters' data-active-class='srch-tlbr__btn--active'>
        <FiltersSvg className='icon' />
        <span className='srch-tlbr__btn-count' data-tsearch-target='filterNumber' />
        <span className='srch-tlbr__btn-label' data-tsearch-target='filtersList' data-search-friendly='status,date,created_by,activity' />
      </button>

      <div className='menu' data-label='Filters' data-dropdown-target='target'>

        <div className='menu__contents'>
          <div className='menu__header'>
            <h4 className='menu__title'>Material filters</h4>
            <span className='menu__close' data-action='click->dropdown#toggle'>Done</span>
          </div>

          <div className='menu__col'>
            <h5 className='menu__col-header'>Filter by category</h5>
            <div className='menu__item menu__item--filter'>
              <input
                type='radio'
                name='category'
                id='category_'
                checked={selectedCategory === ''}
                onClick={() => onCategorySelect('')}
              />
              <label htmlFor='category_'>All Categories</label>
            </div>
            {categories.map(category => (
              <div className='menu__item menu__item--filter' key={category.id}>
                <input
                  type='radio'
                  name='category'
                  id={`category_${category.name}`}
                  checked={selectedCategory === category.name}
                  onClick={() => onCategorySelect(category.name)}
                />
                <label htmlFor={`category_${category.name}`}>{category.name}</label>
              </div>
            ))}
          </div>

          <div className='menu__col'>
            <h5 className='menu__col-header'>Filter by brand</h5>
            <div className='menu__item menu__item--filter'>
              <input
                type='radio'
                name='brand'
                id='brand_'
                checked={selectedBrand === ''}
                onClick={() => onBrandSelect('')}
              />
              <label htmlFor='brand_'>All Brands</label>
            </div>
            {brands.map(brand => (
              <div className='menu__item menu__item--filter' key={brand.id}>
                <input
                  type='radio'
                  name='brand'
                  id={`brand_${brand.name}`}
                  checked={selectedBrand === brand.name}
                  onClick={() => onBrandSelect(brand.name)}
                />
                <label htmlFor={`brand_${brand.name}`}>{brand.name}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

MaterialFilters.defaultProps = {
  selectedCategory: '',
  selectedBrand: ''
}

MaterialFilters.propTypes = {
  onCategorySelect: PropTypes.func.isRequired,
  onBrandSelect: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  selectedBrand: PropTypes.string
}

export default MaterialFilters
