/* eslint no-alert:0 */
import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { DashboardModal } from '@uppy/react'
import axios from '@ytb/axios'

import './css'
import editableImageUploader from './helpers/editable-image-uploader'
import Thumbnail from './components/thumbnail/drag-and-drop-preview'
import ErrorList from './components/error-list'
import AnimatedSpinnerSvg from '../svg/animated-spinner'
import useBeforeUnload from './helpers/use-before-unload-hook'

const LogoUploader = ({
  initialFile,
  endpoint,
  createParams,
  maxFileSize
}) => {
  const [file, setFile] = useState(initialFile)
  const [errors, setErrors] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [uploading, setUploading] = useState(false)

  const onUploadEnd = uploadedFile => {
    setErrors([])
    setFile(uploadedFile)
    setUploading(false)
  }

  const handleError = error => {
    setErrors([...errors, error])
    setUploading(false)
  }

  const fileUploader = useMemo(() => (
    editableImageUploader({
      fileType: 'logo',
      onUploadStart: () => setUploading(true),
      onUploadEnd,
      endpoint,
      params: createParams,
      restrictions: { maxFileSize, allowedFileTypes: ['image/*'], maxNumberOfFiles: 1 },
      handleError,
      method: 'patch',
      onEditComplete: () => setModalOpen(false)
    })
  ), [file])

  const handleDelete = () => {
    if (!window.confirm('Are you sure you want to delete this file?\n\nIt cannot be undone...')) {
      return
    }

    axios.delete('/settings/logo').then(() => setFile(null))
  }

  const handleClose = () => {
    setModalOpen(false)
    fileUploader.cancelAll()
  }

  useBeforeUnload(uploading)

  const uploadButton = () => {
    if (uploading) {
      return (
        <button type='button' className='btn btn--green'>
          <span className='icon'>
            <AnimatedSpinnerSvg className='icon icon--white' />
          </span>
          Uploading
        </button>
      )
    }

    return <button type='button' className='logo-upldr__placeholder' onClick={() => setModalOpen(true)}>Upload logo</button>
  }

  return (
    <div className='logo-upldr'>
      {file
        ? (
          <div className='logo-upldr__thumbnail'>
            <Thumbnail
              file={file}
              onDelete={handleDelete}
              handleOnClick={() => setModalOpen(true)}
            />
          </div>
          )
        : uploadButton()}

      <DashboardModal
        id='Dashboard'
        uppy={fileUploader}
        height={200}
        open={modalOpen}
        plugins={['ImageEditor']}
        proudlyDisplayPoweredByUppy={false}
        autoOpenFileEditor
        showSelectedFiles={false}
        onRequestClose={handleClose}
        closeAfterFinish
        showLinkToFileUploadResult={false}
        locale={{
          strings: {
            dropPaste: 'Drop logo here or %{browse}'
          }
        }}
      />
      <ErrorList errors={errors} />
    </div>
  )
}

LogoUploader.defaultProps = {
  initialFile: null,
  createParams: {}
}

LogoUploader.propTypes = {
  endpoint: PropTypes.string.isRequired,
  maxFileSize: PropTypes.number.isRequired,
  initialFile: PropTypes.object,
  createParams: PropTypes.object
}

export default LogoUploader
