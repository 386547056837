import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import EditSvg from '@/components/svg/edit'
import TrashSvg from '@/components/svg/trash'
import { PaperworkContext } from '../../contexts'
import { getPaperwork, getPaperworkDiscountPercentages } from '../../selectors'
import PercentageField from '../percentage-field'
import PaperworkTotal from '../totals/paperwork'

const DiscountInput = ({ onChange, onRemove }) => {
  const { paperworkUUID } = useContext(PaperworkContext)
  const paperwork = useSelector(state => getPaperwork(state, paperworkUUID))
  const itemDiscounts = useSelector(state => getPaperworkDiscountPercentages(state, paperworkUUID))
  const allItemsDiscountsEqual = itemDiscounts.every(discount => discount === itemDiscounts[0])
  const [showInput, setShowInput] = useState(allItemsDiscountsEqual)

  return (
    <>
      <span className='pwrk-frm__totals-label pwrk-frm__totals-discount'>
        {showInput
          ? (
            <span className='discount__input'>
              <button
                data-test='remove-discount-button'
                className='btn btn--tiny btn--icon-only btn--red-half u-mrgn-r'
                type='button'
                onClick={onRemove}
              >
                <TrashSvg className='icon icon--red' />
              </button>
              <span className='u-mrgn-r'>
                <PercentageField
                  name='discount'
                  testKey='discount'
                  onChange={onChange}
                  onRemove={onRemove}
                  defaultValue={paperwork.discount}
                  noSelectionLabel='No Discount'
                />
              </span>
            </span>
            )
          : (
            <a
              role='button'
              onClick={() => setShowInput(true)}
              className='link u-mrgn-r'
              data-test='change-discount'
              tabIndex='0'
            >
              <span className='btn btn--tiny btn--icon-only btn--blue-half'>
                <EditSvg className='icon icon--blue' />
              </span>
            </a>
            )}
        Discount
      </span>
      <span className='pwrk-frm__totals-value' data-test='discount-total'>
        <PaperworkTotal name='discount' />
      </span>
    </>
  )
}

DiscountInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default DiscountInput
